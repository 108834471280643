import { Stack, TextField } from "@fluentui/react";
import { Tooltip } from "@fluentui/react-components";
import { useState } from "react";

import styles from "./QuestionInput.module.css";

interface Props {
  onSend: (question: string) => void;
  disabled: boolean;
  placeholder?: string;
  clearOnSend?: boolean;
}

export const QuestionInput = ({
  onSend,
  disabled,
  placeholder,
  clearOnSend,
}: Props) => {
  const [question, setQuestion] = useState<string>("");

  const sendQuestion = () => {
    if (disabled || !question.trim()) {
      return;
    }

    onSend(question);

    if (clearOnSend) {
      setQuestion("");
    }
  };

  const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
    if (ev.key === "Enter" && !ev.shiftKey) {
      ev.preventDefault();
      sendQuestion();
    }
  };

  const onQuestionChange = (
    _ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    if (!newValue) {
      setQuestion("");
    } else if (newValue.length <= 1000) {
      setQuestion(newValue);
    }
  };

  const sendQuestionDisabled = disabled || !question.trim();

  return (
    <Stack horizontal className={styles.questionInputContainer}>
      <TextField
        className={styles.questionInputTextArea}
        placeholder={placeholder}
        // multiline
        resizable={false}
        borderless
        value={question}
        onChange={onQuestionChange}
        onKeyDown={onEnterPress}
      />
      <div className={styles.questionInputButtonsContainer}>
        <Tooltip content="Botão de pergunta" relationship="label">
          {/* icon={<Send28Filled primaryFill="#1769FF" />} */}
          <div
            className={styles.questionButton}
            // size="large"
            // disabled={sendQuestionDisabled}
            onClick={sendQuestion}
          >
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0_3_1411"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="25"
                height="25"
              >
                <rect
                  x="0.5"
                  y="0.48999"
                  width="24"
                  height="24"
                  fill="#D9D9D9"
                />
              </mask>
              <g mask="url(#mask0_3_1411)">
                <path
                  d="M20.3 13.415L4.9 19.915C4.56667 20.0483 4.25 20.0191 3.95 19.8275C3.65 19.6358 3.5 19.3566 3.5 18.99V5.98996C3.5 5.62329 3.65 5.34413 3.95 5.15246C4.25 4.96079 4.56667 4.93163 4.9 5.06496L20.3 11.565C20.7167 11.7483 20.925 12.0566 20.925 12.49C20.925 12.9233 20.7167 13.2316 20.3 13.415ZM5.5 17.49L17.35 12.49L5.5 7.48996V10.99L11.5 12.49L5.5 13.99V17.49Z"
                  fill="white"
                />
              </g>
            </svg>
          </div>
        </Tooltip>
      </div>
    </Stack>
  );
};
