import { Example } from "./Example";

import styles from "./Example.module.css";

export type ExampleModel = {
  text: string;
  value: string;
};

const EXAMPLES: ExampleModel[] = [
  {
    text: "Como criar um novo login para acessar o Autorize?",
    value: "Como criar um novo login para acessar o Autorize?",
  },
  {
    text: "Como criar um novo login para acessar o Fature?",
    value: "Como criar um novo login para acessar o Fature?",
  },
  {
    text: "Como desbloqueio meu login ou senha no Autorize?",
    value: "Como desbloqueio meu login ou senha no Autorize?",
  },
  {
    text: "Como desbloqueio meu login ou senha no Fature?",
    value: "Como desbloqueio meu login ou senha no Fature?",
  },
];

interface Props {
  onExampleClicked: (value: string) => void;
}

export const ExampleList = ({ onExampleClicked }: Props) => {
  return (
    <ul className={styles.examplesNavList}>
      {EXAMPLES.map((x, i) => (
        <li className={styles.examplesNavListLineItem} key={i}>
          <Example text={x.text} value={x.value} onClick={onExampleClicked} />
        </li>
      ))}
    </ul>
  );
};
